import styles from "./../styles/privacy-cookie.module.scss"
import menuStyles from "./../styles/commons/terms-privacy-menu.module.scss"
import React, { useContext, useRef, useEffect } from "react"
import Layout from "../components/layout/layout"
import {
  COOKIE_POLICY_EXPLAINED,
  COOKIE_POLICY_TYPES,
  COOKIE_POLICY_PREFERENCES,
  COOKIE_POLICY_MODIFICATIONS,
  COOKIE_POLICY_CONTACT,
  PRIVACY_CONTACT,
  PRIVACY_POLICY_PAGE_URL,
} from "../utils/constants"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import withAppContext from "../contexts/app.context"
import { ShowAnnouncementContext } from "../contexts/show-announcement.context"
import { useLocation } from "@reach/router"
import { Link } from "gatsby"

const PrivacyPage = () => {
  const queryResult = useStaticQuery(graphql`
    {
      seo: markdownRemark(
        fields: {
          relativePath: { eq: "cookie-policy" }
          category: { eq: "seo" }
        }
      ) {
        frontmatter {
          metaTitle
          metaDescription
          metaKeywordList
          metaImageName
        }
      }
    }
  `)

  const {
    seo: {
      frontmatter: {
        metaTitle,
        metaDescription,
        metaKeywordList,
        metaImageName,
      },
    },
  } = queryResult

  const { showAnnouncement } = useContext(ShowAnnouncementContext)

  const refs = {
    [COOKIE_POLICY_EXPLAINED]: useRef(),
    [COOKIE_POLICY_TYPES]: useRef(),
    [COOKIE_POLICY_PREFERENCES]: useRef(),
    [COOKIE_POLICY_MODIFICATIONS]: useRef(),
    [COOKIE_POLICY_CONTACT]: useRef(),
  }

  const scrollToSection = (section, e, offset = 180) => {
    e.preventDefault()
    const offsetTop = refs[section].current.offsetTop + offset
    window.scroll({
      top: offsetTop,
      behavior: "smooth",
    })
  }

  //-- Smooth scroll to section from url
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const scrollToLocation = urlParams.get("scrollto")

  useEffect(() => {
    if (scrollToLocation) {
      refs[scrollToLocation].current.scrollIntoView()
    }
  })

  return (
    <Layout>
      <SEO
        title={metaTitle}
        pageUrl={"/cookie-policy"}
        description={metaDescription}
        keywords={metaKeywordList}
        imageName={!!metaImageName ? `/previews/${metaImageName}` : undefined}
      />
      <div className={styles.privacy}>
        <div className={`container volterra-container`}>
          <div className="row">
            <div
              className={`col-12 col-lg-4 ${styles.privacy__menu} ${menuStyles.menu}`}>
              <h5 className={menuStyles.menu__title}>Cookie Policy</h5>
              <div className={styles.privacy__menucontent}>
                <a
                  href="#"
                  className={menuStyles.menu__link}
                  onClick={e => {
                    scrollToSection(COOKIE_POLICY_EXPLAINED, e)
                  }}>
                  1. "Cookies" Explained and Why They are Used
                </a>
                <a
                  href="#"
                  className={menuStyles.menu__link}
                  onClick={e => {
                    scrollToSection(COOKIE_POLICY_TYPES, e)
                  }}>
                  2. Types of Cookies Used by Volterra and for What Purpose
                </a>
                <a
                  href="#"
                  className={menuStyles.menu__link}
                  onClick={e => {
                    scrollToSection(COOKIE_POLICY_PREFERENCES, e)
                  }}>
                  3. How to Manage Your Cookie Preferences
                </a>
                <a
                  href="#"
                  className={menuStyles.menu__link}
                  onClick={e => {
                    scrollToSection(COOKIE_POLICY_MODIFICATIONS, e)
                  }}>
                  4. Modifications to this Cookie Policy
                </a>
                <a
                  href="#"
                  className={menuStyles.menu__link}
                  onClick={e => {
                    scrollToSection(COOKIE_POLICY_CONTACT, e)
                  }}>
                  5. How to Contact Us
                </a>
              </div>
            </div>
            <div className={`col-12 col-lg-8 ${styles.privacy__body}`}>
              <h2 className={styles.privacy__title}>Cookie Policy</h2>
              <p className={styles.privacy__date}>Effective: March 3, 2020</p>
              <p className={styles.privacy__description}>
                Volterra, Inc. (“<strong>Volterra</strong>,” “
                <strong>we</strong>,” “<strong>our</strong>,” or “
                <strong>us</strong>”) is committed to transparency with respect
                to its data collection and use practices. This Cookie Policy (“
                <strong>Cookie Policy</strong>”) explains how we use cookies
                throughout the Site and use of the Services and your related
                choices. Please note that this Cookie Policy is provided as a
                statement about privacy and Volterra’s use of cookies and
                similar technologies. Capitalized terms used in this Cookie
                Policy but not defined herein will have the meanings given to
                them in the{" "}
                <a
                  aria-label="Open Privacy Policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={PRIVACY_POLICY_PAGE_URL}>
                  privacy policy
                </a>
                .
              </p>

              <div
                className={
                  styles.privacy__section +
                  " " +
                  (showAnnouncement ? styles.privacy__sectionIndent : "")
                }
                ref={refs[COOKIE_POLICY_EXPLAINED]}
                id="cookies_explained">
                <h2 className={styles.privacy__title}>
                  1. "Cookies" Explained and Why They are Used
                </h2>
                <p className={styles.privacy__text}>
                  A cookie is a small piece of data (text file) that a website –
                  when visited by a user – asks your browser to store on your
                  computer or device in order to remember information about you,
                  such as your language and/or currency preference or login
                  information. You can find more information about cookies at:{" "}
                  <a href="https://www.allaboutcookies.org/" target="_blank">
                    www.allaboutcookies.org
                  </a>{" "}
                  or{" "}
                  <a href="https://www.youronlinechoices.com/" target="_blank">
                    www.youronlinechoices.eu
                  </a>
                  .
                </p>
                <p className={styles.privacy__text}>
                  The term cookie actually describes a range of technologies,
                  including: pixel tags (transparent graphic images placed on a
                  web page or in an email, which indicate that a page or email
                  has been viewed), mobile device identifiers, and web storage
                  used in desktop software or mobile devices. We’ll use the term
                  cookie throughout this Cookie Policy to cover all of these
                  technologies, but we’ll make sure we provide you with clear
                  details about them all so that you can make informed choices
                  about your cookie settings.
                </p>
                <p className={styles.privacy__text}>
                  Cookies may be used for many different purposes. For example,
                  cookies can be used to show that you have previously visited
                  the Sites or used a feature of the Services and to identify
                  which parts of the Services you might be most interested in.
                  Cookies can also improve your online experience by storing
                  your preferences during your visit to the Sites and/or use of
                  the Services. Cookies can also be used for security, fraud
                  detection and authentication purposes.
                </p>

                <h3>Categories of Cookies</h3>

                <h4>Session and Persistent Cookies</h4>
                <ul className={styles.privacy__list}>
                  <li className={styles.privacy__listItem}>
                    <strong>Session Cookies</strong> - these are cookies which
                    expire once you close your web browser; and
                  </li>
                  <li className={styles.privacy__listItem}>
                    <strong>Persistent Cookies</strong> - these are cookies
                    which stay on your device for a set period of time or until
                    you delete them.
                  </li>
                </ul>

                <h4>First and Third Party Cookies</h4>
                <ul className={styles.privacy__list}>
                  <li className={styles.privacy__listItem}>
                    <strong>First-party cookies</strong> - these are cookies set
                    by the website that you are visiting when you are visiting
                    the website, either by us, or by a third party at our
                    request;
                  </li>
                  <li className={styles.privacy__listItem}>
                    <strong>Third-party cookies</strong> - these are cookies
                    that are set by a party other than that of the website you
                    are visiting. If you visit the Sites and/or access or use
                    the Services and another party sets a cookie through that
                    website, other than at our request, this would be a
                    third-party cookie.
                  </li>
                </ul>
              </div>

              <div
                className={
                  styles.privacy__section +
                  " " +
                  (showAnnouncement ? styles.privacy__sectionIndent : "")
                }
                ref={refs[COOKIE_POLICY_TYPES]}
                id="cookie_types">
                <h2 className={styles.privacy__title}>
                  2. Types of Cookies Used by Volterra and for What Purpose
                </h2>
                <p className={styles.privacy__text}>
                  Volterra, or our service providers acting on our behalf, uses
                  the types of cookies identified above in the following ways:
                </p>
                <p className={styles.privacy__text}>
                  <strong>Strictly Necessary</strong> – These cookies are
                  necessary for the Services to function and cannot be switched
                  off in our systems. They are usually only set in response to
                  actions made by you which amount to a request for services,
                  such as setting your privacy preferences, logging in or
                  filling in forms. You can set your browser to block or alert
                  you about these cookies, but some parts of the Services may
                  not work. For example, these cookies are used to:
                </p>
                <ul className={styles.privacy__list}>
                  <li className={styles.privacy__listItem}>
                    Remember information that you fill in when performing
                    certain activities while accessing or using the Services.
                  </li>
                  <li className={styles.privacy__listItem}>
                    Pass information from one page to the next, for example when
                    filling out support forms or surveys.
                  </li>
                  <li className={styles.privacy__listItem}>
                    Provide the Services according to your preferences such as
                    language and location.
                  </li>
                  <li className={styles.privacy__listItem}>
                    Read your browser and device settings to optimize the
                    display of the Sites.
                  </li>
                  <li className={styles.privacy__listItem}>
                    Locate and identify misuse of the Services.
                  </li>
                  <li className={styles.privacy__listItem}>
                    Load the Sites uniformly to maintain accessibility.
                  </li>
                  <li className={styles.privacy__listItem}>
                    Fraud prevention and authentication.
                  </li>
                </ul>

                <p className={styles.privacy__text}>
                  <strong>Performance/Analytics Cookies</strong> — These cookies
                  collect information about how visitors use our services, for
                  instance which pages visitors go to most often, and if they
                  get error messages from web pages or in interactions with the
                  Services. These cookies collect anonymous information on the
                  pages visited. All information these cookies collect is
                  aggregated and therefore anonymous. It is only used to improve
                  how our Services perform. Web analytics that use cookies to
                  gather data to enhance the performance of a website fall into
                  this category. For example, they may be used for testing
                  designs and ensuring a consistent look and feel is maintained
                  for the user. This category does not include cookies used for
                  behavioral/targeted advertising networks. We use cookies and
                  other similar technologies to analyze how the Services are
                  accessed, used, or performing. For example, these cookies are
                  used to:
                </p>
                <ul className={styles.privacy__list}>
                  <li className={styles.privacy__listItem}>
                    Maintain, operate, and continually improve the Services.
                  </li>
                  <li className={styles.privacy__listItem}>
                    Keep track of the number of visitors to the pages within the
                    Sites.
                  </li>
                  <li className={styles.privacy__listItem}>
                    Keep track of the length of time that each visitor spends on
                    the pages within the Site and/or the features of the
                    Services.
                  </li>
                  <li className={styles.privacy__listItem}>
                    Determine the order in which a visitor visits the various
                    pages within the Sites and/or features of the Services.
                  </li>
                  <li className={styles.privacy__listItem}>
                    Determine the website a visitor was at before navigating to
                    the Sites and the website a visitor is going to immediately
                    after leaving the Sites.
                  </li>
                  <li className={styles.privacy__listItem}>
                    Identify performance issues with the Services (or any
                    component thereof).
                  </li>
                  <li className={styles.privacy__listItem}>
                    Assess which parts of the Services (or any component
                    thereof) need improvement
                  </li>
                  <li className={styles.privacy__listItem}>
                    Obtain information from our email newsletters or other
                    communications we send to you, including whether you opened
                    or forwarded a newsletter or clicked on any of its content,
                    which tells us about our newsletters' effectiveness and
                    helps us ensure that we're delivering information that you
                    find interesting.
                  </li>
                </ul>

                <p className={styles.privacy__text}>
                  <strong>Functionality Cookies</strong> — These cookies allow
                  the website to remember choices you make (such as your user
                  name, language or the region you are in) and provide enhanced,
                  more personal features. These cookies can also be used to
                  remember changes you have made to text size, fonts and other
                  parts of web pages that you can customize. The information
                  these cookies collect may be anonymized and they cannot track
                  your browsing activity on other websites. These cookies
                  remember choices you make to improve your experience. These
                  cookies let us operate and provide the Services to you
                  according to your preferences. For example, we use these
                  cookies to:
                </p>
                <ul className={styles.privacy__list}>
                  <li className={styles.privacy__listItem}>
                    Remember information that you fill in when performing
                    certain activities in connection with your use of the
                    Services.
                  </li>
                  <li className={styles.privacy__listItem}>
                    Store your preferences such as language and location.
                  </li>
                </ul>

                <p className={styles.privacy__text}>
                  <strong>Targeting/Advertising Cookies</strong> – These cookies
                  are used to deliver advertisements more relevant to you and
                  your interests. They are also used to limit the number of
                  times you see an advertisement as well as help measure the
                  effectiveness of the advertising campaigns. They are usually
                  placed by advertising networks with the website operator’s
                  permission. They remember that you have visited a website and
                  this information is shared with other organizations such as
                  advertisers. Targeting or advertising cookies will often be
                  linked to site functionality provided by the other
                  organizations. Volterra uses the third party service providers
                  listed below that use these cookies to:
                </p>
                <ul className={styles.privacy__list}>
                  <li className={styles.privacy__listItem}>
                    Advertise Volterra across the Internet
                  </li>
                  <li className={styles.privacy__listItem}>
                    Tailor our marketing to better suit your needs and only
                    display ads that are relevant to you
                  </li>
                </ul>
                <p className={styles.privacy__text}>
                  Please refer to the privacy policies linked below of the third
                  party advertising services currently utilized through the
                  Services:
                </p>
                <ul className={styles.privacy__list}>
                  <li className={styles.privacy__listItem}>
                    Google AdWords and AdSense:{" "}
                    <a
                      href="https://policies.google.com/privacy?hl=en"
                      target="_blank">
                      https://policies.google.com/privacy?hl=en
                    </a>
                  </li>
                </ul>
              </div>

              <div
                className={
                  styles.privacy__section +
                  " " +
                  (showAnnouncement ? styles.privacy__sectionIndent : "")
                }
                ref={refs[COOKIE_POLICY_PREFERENCES]}
                id="manage_prefs">
                <h2 className={styles.privacy__title}>
                  3. How to Manage Your Cookie Preferences
                </h2>
                <p className={styles.privacy__text}>
                  Where required by applicable law, we will obtain your consent
                  prior to setting cookies (except for strictly necessary
                  cookies). In order to manage your cookie preferences, you may
                  alter the cookie settings in your browser settings at any
                  time. You may accept all, or certain, cookies. If you do
                  disable our cookies in your browser settings, you may find
                  that certain Services will not work.
                </p>
                <p className={styles.privacy__text}>
                  To learn more about cookies, including how to manage cookies
                  and opt-out of cookies being placed on your device, please
                  visit{" "}
                  <a
                    href="http://www.allaboutcookies.org/manage-cookies/index.html"
                    target="_blank">
                    http://www.allaboutcookies.org/manage-cookies/index.html
                  </a>
                  .
                </p>

                <h4>Browser Cookies</h4>
                <p className={styles.privacy__text}>
                  While tracking is widespread over the internet, there are
                  things you can do to minimize these activities, as explained
                  here:
                </p>
                <ul className={styles.privacy__list}>
                  <li className={styles.privacy__listItem}>
                    You may download the Google Analytics opt-out browser add-on{" "}
                    <a
                      href="https://tools.google.com/dlpage/gaoptout/"
                      target="_blank">
                      here
                    </a>
                    .
                  </li>
                  <li className={styles.privacy__listItem}>
                    You may turn on the Do Not Track (“DNT”) setting in your
                    browser, which will enable your browser to send a special
                    signal to websites, analytics companies, plug in providers,
                    and other web services you encounter while browsing to stop
                    tracking your activity. To turn on your DNT signal, please
                    visit{" "}
                    <a
                      href="https://allaboutdnt.com/#adjust-settings"
                      target="_blank">
                      https://allaboutdnt.com/#adjust-settings
                    </a>
                    . Please note that not all companies will respond to or
                    honor DNT signals.
                  </li>
                  <li className={styles.privacy__listItem}>
                    You can also download the Google Analytics opt-out browser
                    add-on at{" "}
                    <a
                      href="https://tools.google.com/dlpage/gaoptout/"
                      target="_blank">
                      https://tools.google.com/dlpage/gaoptout/
                    </a>
                    .
                  </li>
                  <li className={styles.privacy__listItem}>
                    Note that Volterra, does not respond to DNT signals.
                  </li>
                </ul>
              </div>

              <div
                className={
                  styles.privacy__section +
                  " " +
                  (showAnnouncement ? styles.privacy__sectionIndent : "")
                }
                ref={refs[COOKIE_POLICY_MODIFICATIONS]}
                id="policy_modifications">
                <h2 className={styles.privacy__title}>
                  4. Modifications to this Cookie Policy
                </h2>
                <p className={styles.privacy__text}>
                  Volterra reserves the right to amend this Cookie Policy and
                  the cookies included in the lists at any time and without
                  notice. The updated and amended Cookie Policy will be
                  effective upon posting. When we make material changes to this
                  Policy, we’ll provide you with prominent notice e.g., by
                  displaying a prominent notice on the Sites. If you do not
                  agree to the revised Cookie Policy, you should alter your
                  preferences, or consider ceasing use of the Services. By
                  continuing to access or make use of the Services after the
                  changes become effective, you agree to be bound by the revised
                  Cookie Policy.
                </p>
              </div>

              <div
                className={
                  styles.privacy__section +
                  " " +
                  (showAnnouncement ? styles.privacy__sectionIndent : "")
                }
                ref={refs[PRIVACY_CONTACT]}
                id="contact_us">
                <h2 className={styles.privacy__title}>5. How to Contact Us</h2>
                <p className={styles.privacy__text}>
                  If you have any questions about this Cookie Policy, please
                  contact us by email at{" "}
                  <a href="mailto:privacy@volterra.io">privacy@volterra.io</a>{" "}
                  or by writing to us at the following address:
                </p>
                <p className={styles.privacy__text}>
                  Volterra, Inc.
                  <br />
                  Attn: Privacy Office
                  <br />
                  801 5th Ave
                  <br />
                  Seattle, Washington 98104
                  <br />
                  United States
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default withAppContext(PrivacyPage)
